/**
 * Returns the protocol part of the URL.
 *
 * @param {string} url The full URL.
 *
 * @example
 * ```js
 * const protocol1 = getProtocol( 'tel:012345678' ); // 'tel:'
 * const protocol2 = getProtocol( 'https://wordpress.org' ); // 'https:'
 * ```
 *
 * @return {string|void} The protocol part of the URL.
 */
export function getProtocol( url ) {
	const matches = /^([^\s:]+:)/.exec( url );
	if ( matches ) {
		return matches[ 1 ];
	}
}
